import { mapState } from 'vuex'
export default {
  data () {
    return {
      isCCTV: process.env.VUE_APP_ISCCTV === 'true'
    }
  },
  computed: {
    ...mapState({
      createUser: (state) => state.user.createUser,
      roleId: (state) => state.user.roleId,
      loginId: (state) => state.user.id
    }),
    isSupperAdminStaff () {
      return +this.createUser === 1 && ![1, 2, 3].includes(+this.roleId)
    },
    isYunqing () {
      return this.loginId === 25 && !this.isCCTV
    }
  },
  methods: {
  }
}
